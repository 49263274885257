<!--<div class="container">
    <div class="row">
        <div class="col-md-4">
            <img src="../../assets/images/construction.jpg" alt="image">
        </div>
        <div class="col-md-4"><h1>This website is still in development. Come back later</h1></div>
    </div>
    <div class="row" style="padding-top: 5px;">
        <div class="col-md-4">
            <img src="../../assets/images/me_wedding.JPG" alt="image" width="300px" height="400px">
        </div>
        <div class="col-md-4">
            <h1>Introduction</h1>
            This is the personal web presence of Markus Röttgermann.
            I will make use of my little web space to post interests, personal data for potential future employers and use it as
            a personal playground for testing web technologies. I graduated with a Bachelor in computer science on April 2021 and have a passion for exploring new
            technologies. This also led to the creation of this domain.
        </div>
        <div></div>
    </div>
    <div class="row"></div>
</div>-->

<div class="mask flex-center rgba-black-strong">
<mdb-carousel [isControls]="true" [animation]="'slide'" [keyboard]="true" [noPause]="true" [interval]="0">
  <mdb-carousel-item>
    <!--<div class="view">
      <img class="d-block" src="https://wallpaper.wiki/wp-content/uploads/2017/05/wallpaper.wiki-Full-HD-1920x1080-Nature-Wallpapers-PIC-WPE0012781.jpg"
        alt="First slide">
      <div class="mask flex-center rgba-black-light">
      </div>
    </div>-->
    <div class="container" style="height: 100vh">
        <div class="row justify-content-center" style="padding-top: 20%">
            <!--<div class="col-md-4">
                <img src="../../assets/images/me_wedding.JPG" alt="image" width="300px" height="400px">
            </div>-->
            <div class="col-md-4">
              <div class="text-white">
                <h1>Welcome</h1>
                I'm a CS graduate and junior web developer based in Germany. You can get a most likely outdated CV of mine in the according section. This angular based web app is currently in development and will be extended in the future. If you want to get in touch feel free to send me a mail or add me on my socials. For business inquiries I'd prefer to get contacted via mail
              </div>
            </div>
        </div>
    </div>
  </mdb-carousel-item>
  <mdb-carousel-item>
    <!--<div class="view">
      <img class="d-block" src="http://www.kinyu-z.net/data/wallpapers/228/1502404.jpg" alt="Second slide">
      <div class="mask flex-center rgba-black-light">
      </div>
    </div>-->
    <div class="container" style="height: 100vh">
        <div class="row justify-content-center" style="padding-top: 20%">
            <!--<div class="col-md-4">
                <img src="../../assets/images/me_wedding.JPG" alt="image" width="300px" height="400px">
            </div>-->
            <div class="col-md-4">
              <div class="text-white">
                <h1>Short Bio</h1>
I was born and raised in the German state Rhineland-Palatinate. After finishing the 10th grade of high school, I focused on economics as my higher education entrance for university. Quickly after claiming my high school diploma, I changed my plans thinking a more practical approach would fit my future plans even more. I decided to attend a 3-year job training which mixes practice and school and leads to a concrete job entrance level. After completing I stayed at my training company for 1 more year before deciding to take a break to explore the beautiful country of Australia. Working and traveling for a year was the most formative experience I made in my life. It led to me changing from the field of economy to Computer Science and gave me a 'can do' attitude to attend university at the age of 24. A few months ago, I graduated from university holding a CS degree and working in a field I could only dream of.
              </div>
            </div>
        </div>
    </div>
  </mdb-carousel-item>
  <mdb-carousel-item>
    <!--<div class="view">
      <img class="d-block" src="https://wallpaper.wiki/wp-content/uploads/2017/05/wallpaper.wiki-Beautiful-Full-HD-Images-PIC-WPE0011754.jpg"
        alt="Third slide">
      <div class="mask flex-center rgba-black-light">
      </div>
    </div>-->
    <div class="container" style="height: 100vh">
        <div class="row justify-content-center" style="padding-top: 20%">
            <!--<div class="col-md-4">
                <img src="../../assets/images/me_wedding.JPG" alt="image" width="300px" height="400px">
            </div>-->
            <div class="col-md-4">
              <div class="text-white">

                <h1>Future changes</h1>
                Download section will be implemented shortly,
                You will be able to create your own account,
                multi language support,
                design changes,
                moments section,

              </div>
            </div>
        </div>
    </div>
  </mdb-carousel-item>
</mdb-carousel>

</div>
