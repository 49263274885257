<!--Navbar-->
<mdb-navbar
  SideClass="navbar navbar-expand-lg navbar-dark unique-color"
  [containerInside]="false"
>
  <!-- Navbar brand -->
  <mdb-navbar-brand><a class="navbar-brand" href="#">mrde</a></mdb-navbar-brand>

  <!-- Collapsible content -->
  <links>
    <!-- Links -->
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link waves-light" mdbWavesEffect
          >Home<span class="sr-only">(current)</span></a
        >
      </li>
      <li class="nav-item" (click)="downloadCv()">
        <a class="nav-link waves-light" mdbWavesEffect>CV</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-light" mdbWavesEffect>Moments</a>
      </li>

      <!-- Dropdown -->
      <!--<li class="nav-item dropdown" dropdown>
          <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
            Basic dropdown<span class="caret"></span></a>
          <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
            <a class="dropdown-item waves-light" mdbWavesEffect href="#">Action</a>
            <a class="dropdown-item waves-light" mdbWavesEffect href="#">Another action</a>
            <a class="dropdown-item waves-light" mdbWavesEffect href="#">Something else here</a>
            <div class="divider dropdown-divider"></div>
            <a class="dropdown-item waves-light" mdbWavesEffect href="#">Separated link</a>
          </div>
        </li>-->
    </ul>
    <!-- Links -->

    <ul class="navbar-nav ml-auto nav-flex-icons">
      <li class="nav-item">
        <a class="nav-link" href="mailto:markusroettgermann@yahoo.de">
          <mdb-icon fas icon="envelope"></mdb-icon>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="https://github.com/maro1993">
          <mdb-icon fab icon="github"></mdb-icon>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="https://www.linkedin.com/in/markus-r%C3%B6ttgermann-b8a356193/"
        >
          <mdb-icon fab icon="linkedin"></mdb-icon>
        </a>
      </li>
    </ul>
  </links>
  <!-- Collapsible content -->
</mdb-navbar>
<!--/.Navbar-->
